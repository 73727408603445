import type { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';

// TODO This constant will be removed when all types of Sticker will be handled by Hodor
const TOP_AVAILABLE_HODOR_STICKER_TYPES: ApiV2SpyroStrateContentSticker['type'][] =
  [
    'coming-soon',
    'date',
    'free-to-air',
    'get',
    'offered-content',
    'subscribe',
    'theatrical-release',
  ];

const BOTTOM_AVAILABLE_HODOR_STICKER_TYPES: ApiV2SpyroStrateContentSticker['type'][] =
  ['duration'];

// These types of sticker will be soon available, so we only add the toggle for these ones
// They will be removed progressively
const UPCOMING_HODOR_STICKERS: ApiV2SpyroStrateContentSticker['type'][] = [
  'last-days',
];

/**
 * This function will extract the hodor sticker from the stickers object returned by hodor
 * It will also check if the type of sticker is available, and check the feat toggle for some
 * types not handle now by Hodor (see: https://canal-wiki.canal-plus.com/display/2M/Chantiers+stickers+centralisation)
 * @param stickers ApiV2SpyroStrateContentSticker[]
 * @param isFeatUpcomingHodorSticker boolean
 * @returns
 */
export const getHodorSticker = ({
  stickers,
  isFeatUpcomingHodorSticker = false,
  position = 'top',
}: {
  stickers?: ApiV2SpyroStrateContentSticker[];
  isFeatUpcomingHodorSticker?: boolean;
  position?: 'top' | 'bottom';
}): ApiV2SpyroStrateContentSticker | undefined => {
  if (!stickers?.[0]) {
    return undefined;
  }

  const topAvailableHodorStickers = isFeatUpcomingHodorSticker
    ? TOP_AVAILABLE_HODOR_STICKER_TYPES.concat(UPCOMING_HODOR_STICKERS)
    : TOP_AVAILABLE_HODOR_STICKER_TYPES;

  const availableHodorStickers =
    position === 'top'
      ? topAvailableHodorStickers
      : BOTTOM_AVAILABLE_HODOR_STICKER_TYPES;

  return stickers.find((sticker) => {
    return availableHodorStickers.includes(sticker.type);
  });
};
