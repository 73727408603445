import { Ratio } from '@canalplus/mycanal-commons';

export const getHeightMediaFromRatio = (width: number, ratio: Ratio) => {
  switch (ratio) {
    case Ratio.Ratio43:
      return (3 * width) / 4;
    case Ratio.Ratio34:
      return (4 * width) / 3;
    case Ratio.Ratio169:
      return (9 * width) / 16;
    case Ratio.Ratio166:
      return (6 * width) / 16;
    case Ratio.Ratio23:
      return (3 * width) / 2;
    case Ratio.Ratio236:
      return (6 * width) / 23;
    case Ratio.Ratio47:
      return (7 * width) / 4;
    case Ratio.Square:
      return width;

    default:
      return width;
  }
};
