import type { StickerVariant } from '@canalplus/dive';
import { Sticker as DiveSticker, PlayerPlaySvg } from '@canalplus/dive';
import type { ApiV2SpyroStrateContentSticker } from '@dce-front/hodor-types/api/v2/common/dto/stickers/definitions';
import type { ComponentProps, JSX } from 'react';
import {
  MAP_HODOR_BOTTOM_STICKER_TYPE_TO_STICKER_VARIANT,
  MAP_HODOR_TOP_STICKER_TYPE_TO_STICKER_VARIANT,
} from '../../constants/stickers';
import {
  dateFormat,
  getCurrentTimestamp,
} from '../../helpers/date/date-helper';
import { useInvariantSelector } from '../../helpers/hooks/useInvariantSelector';
import { useTranslation } from '../../lang';
import { langKeySelector } from '../../store/slices/application-selectors';

export const STICKER_LABEL_LIVE = 'LIVE';

type StickerProps = {
  /** Optional additional class names for custom styling of the sticker component. */
  className?: string;
  /**
   * Timestamp (in milliseconds) for the **end** of the media associated with the sticker.
   * Used to determine when the media ends.
   */
  endTime?: number;
  /**
   * Timestamp (in milliseconds) for the **start** of the media associated with the sticker.
   * Used to determine when the media starts, such as for upcoming events or programs.
   */
  position?: 'bottom' | 'top';
  /**
   * Timestamp (in milliseconds) for the **start** of the media associated with the sticker.
   * Used to determine when the media starts, such as for upcoming events or programs.
   */
  startTime?: number;
  /**
   * Data for HODOR-generated Stickers, containing the sticker `type` and `label`.
   * @see {@link https://canal-wiki.canal-plus.com/pages/viewpage.action?pageId=236094684 Hodor Sticker specs}
   */
  sticker?: ApiV2SpyroStrateContentSticker;
  /**
   * The variant type for the DIVE Sticker, defining its visual style.
   * @see {@link https://www.figma.com/design/VkDoI3oG7dgylYIUkqwXPk/%5BDIVE---Web-UI-KIT%5D---V1.2.1---15%2F12%2F2022?node-id=1016-70628&m=dev DIVE Sticker specs}
   */
  variant?: `${StickerVariant}`;
};

export const useGetDiveStickerProps = ({
  sticker,
  startTime,
  endTime,
  variant,
  position = 'top',
}: Omit<StickerProps, 'className'>):
  | Pick<ComponentProps<typeof DiveSticker>, 'variant' | 'label' | 'icon'>
  | undefined => {
  const langKey = useInvariantSelector(langKeySelector);
  const { t } = useTranslation();

  if (sticker && sticker.label) {
    const diveStickerVariant =
      position === 'bottom'
        ? MAP_HODOR_BOTTOM_STICKER_TYPE_TO_STICKER_VARIANT[sticker.type]
        : MAP_HODOR_TOP_STICKER_TYPE_TO_STICKER_VARIANT[sticker.type];

    if (diveStickerVariant) {
      return {
        variant: diveStickerVariant,
        label: sticker.label,
        icon:
          sticker.type === 'offered-content' ? (
            <PlayerPlaySvg title={sticker.label} />
          ) : undefined,
      };
    }
    return undefined;
  }

  if (variant === 'live') {
    return {
      variant: 'live',
      label: STICKER_LABEL_LIVE,
    };
  }

  if (startTime && endTime && getCurrentTimestamp() <= startTime) {
    const stickerDateLabel = dateFormat(t, startTime, langKey, true);
    if (stickerDateLabel) {
      return {
        variant: 'date',
        label: stickerDateLabel,
      };
    }
  }

  return undefined;
};

/**
 * Component used to render DIVE Stickers based on the provided data and state.
 *
 * The component conditionally renders different types of stickers based on:
 * - The `sticker` data provided by the HODOR API
 * - The `variant` of the sticker (such as `live` or `date`)
 * - Timing information (`startTime` and `endTime`) for media-related stickers.
 */
function Sticker({
  className,
  endTime,
  position = 'top',
  startTime,
  sticker,
  variant,
}: StickerProps): JSX.Element | null {
  const langKey = useInvariantSelector(langKeySelector);
  const { t } = useTranslation();

  if (sticker && sticker.label) {
    const diveStickerVariant =
      position === 'bottom'
        ? MAP_HODOR_BOTTOM_STICKER_TYPE_TO_STICKER_VARIANT[sticker.type]
        : MAP_HODOR_TOP_STICKER_TYPE_TO_STICKER_VARIANT[sticker.type];
    if (diveStickerVariant) {
      return (
        <DiveSticker
          variant={diveStickerVariant}
          label={sticker.label}
          className={className}
          icon={
            sticker.type === 'offered-content' ? (
              <PlayerPlaySvg title={sticker.label} />
            ) : undefined
          }
        />
      );
    }
    return null;
  }

  if (variant === 'live') {
    return (
      <DiveSticker
        variant="live"
        label={STICKER_LABEL_LIVE}
        className={className}
      />
    );
  }

  if (startTime && endTime && getCurrentTimestamp() <= startTime) {
    const stickerDateLabel = dateFormat(t, startTime, langKey, true);
    if (stickerDateLabel) {
      return (
        <DiveSticker
          variant="date"
          label={stickerDateLabel}
          className={className}
        />
      );
    }
  }

  return null;
}

export default Sticker;
